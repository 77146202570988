const addresses = {
	xbtc: "0xB6eD7644C69416d67B522e20bC294A9a9B405B31",
	store: "0x9F264605Cc2671dcC45B8135dBE5EdaE442DD145",
	rinkebyResolver: "", 
	resolver: "0x4976fb03C32e5B8cfe2b6cCB31c09Ba78EBaBa41",
	rinkebyReverse: "",
	reverse: "0x084b1c3C81545d370f3634392De611CaaBFf8148",
	null: "0x0000000000000000000000000000000000000000"
};

export default addresses;
